
import {
    defineComponent, onBeforeUnmount, ref, Ref, watch
} from 'vue';
import { getRemoteApiUrl } from '@/components/view/pm/device-info-setting-dialog/utils';
import HttpRequest from '@/util/axios.config';
import { propertyBaseRouter, propertySubRouter } from '@/router';
import { community, device } from '@/data';
import JumpPage from '@/components/view/common/jump-page/index.vue';
import deviceInfoSettingDialog from '@/components/view/pm/device-info-setting-dialog/index';
import loadingAnimationDialog from '@/components/view/pm/loading-animation-dialog';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb/index';
import { DeviceInfoItemData, DeviceInfoData, DeviceInfoKey } from '../types/all.type';
import { getProjectData } from '../composables/util';
import { ElMessageBox } from 'element-plus';

const titleType = 1;
const labelType = 2;
const labelCustomize = 3;
const publicType = 4;

export default defineComponent({
    components: {
        propertyBreadCrumb,
        deviceInfoSettingDialog,
        JumpPage,
        loadingAnimationDialog
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const breadCrumbHeaders = [
            {
                label: WordList.OrderDevice,
                path: `/${propertyBaseRouter}/${propertySubRouter.device}`
            },
            {
                label: WordList.PersonuserInfo
            }
        ];
        const deviceType: Ref<{ [key in number]: string }> = ref({});
        const IsShowBuildNames = ref(false);
        const detailForm: Ref<Array<DeviceInfoItemData>> = ref([]);
        const deviceDetail: Ref<DeviceInfoData> = ref<DeviceInfoData>({
            Basic: '',
            BuildNames: [],
            UnitName: '',
            RoomName: '',
            Location: '',
            MAC: '',
            SipAccount: '',
            LastConnection: '',
            Grade: '1',
            Owner: '',
            Product: '',
            Firmware: '',
            Hardware: '',
            Network: '',
            IPAddress: '',
            SubnetMask: '',
            Gateway: '',
            PrimaryDNS: '',
            ProjectType: '',
            SecondaryDNS: '',
            IsAllBuild: 0,
            IsAllDepartment: 0
        });
        const projectType = ref('');

        const isShowJumpPage = ref(false);
        const isShowLoadingDialog = ref(false);
        const remoteUrl = ref('');
        const accessId = ref('');
        const isLoading = ref(false);
        const jumpUrl = ref('');

        let timer: NodeJS.Timer | null = null;
        let setTimeoutTimer: NodeJS.Timer;
        function clearTimer() {
            clearInterval(timer as NodeJS.Timer);
            timer = null;
        }

        function jumpToPage() {
            HttpRequest.get('v3/web/common/device/remoteStatus', [{
                AccessId: accessId.value
            }, false], (res: { status: number }) => {
                if (res.status === 1) {
                    isShowLoadingDialog.value = false;
                    const newPage = window.open(`${remoteUrl.value}`);
                    if (newPage === null) {
                        jumpUrl.value = remoteUrl.value;
                        isShowJumpPage.value = true;
                    }
                    clearTimer();
                    isLoading.value = false;
                } else if (res.status === 0) {
                    if (!timer) {
                        timer = setInterval(() => {
                            jumpToPage();
                        }, 3000);
                    }
                } else {
                    clearTimer();
                    isShowLoadingDialog.value = false;
                    clearTimeout(setTimeoutTimer);
                    isLoading.value = false;
                    ElMessageBox.alert(
                        WordList.NetworkErrorPleaseTryAgain,
                        WordList.TabelDeleteTitle,
                        {
                            type: 'error',
                            confirmButtonText: WordList.TabelFootSure
                        }
                    );
                }
            }, (error: string) => {
                isShowLoadingDialog.value = false;
                clearTimer();
                clearTimeout(setTimeoutTimer);
                isLoading.value = false;
            });
        }

        const remote = () => {
            remoteUrl.value = '';
            accessId.value = '';
            isLoading.value = true;
            clearTimer();
            // 30秒连接不上
            setTimeoutTimer = setTimeout(() => {
                isShowLoadingDialog.value = false;
                if (isLoading.value) {
                    ElMessageBox.alert(
                        WordList.ProcessingTimeout,
                        WordList.TabelDeleteTitle,
                        {
                            type: 'error',
                            confirmButtonText: WordList.TabelFootSure
                        }
                    );
                    clearTimer();
                }
            }, 60000);
            isShowLoadingDialog.value = true;
            const url = getRemoteApiUrl(projectType.value);
            HttpRequest.post(url, {
                MAC: deviceDetail.value.MAC
            }, [(res: any) => {
                accessId.value = res.accessId;
                remoteUrl.value = res.url;
                clearTimer();
                jumpToPage();
            }, false], (error: string) => {
                isShowLoadingDialog.value = false;
                clearTimeout(setTimeoutTimer);
                clearTimer();
                isLoading.value = false;
            });
        };

        const getPMDeviceInfo = () => {
            device.getPMDeviceInfo(props.id, (res: DeviceInfoData) => {
                deviceDetail.value = { ...res };
                if ((deviceDetail.value as DeviceInfoData).Grade === '1') {
                    IsShowBuildNames.value = true;
                }
                detailForm.value.forEach((key, index: number) => {
                    const ele = detailForm.value[index].name as DeviceInfoKey;
                    if (detailForm.value[index].type === labelCustomize) {
                        detailForm.value[index].text = deviceType.value[Number(deviceDetail.value[ele])];
                    } else if (detailForm.value[index].type === publicType) {
                        if (deviceDetail.value.IsAllBuild === 1 || deviceDetail.value.IsAllDepartment === 1) {
                            detailForm.value[index].text = community.ProjectType.value === 'community'
                                ? WordList.AllBuildings
                                : WordList.AllDepartments;
                        } else {
                            detailForm.value[index].text = (deviceDetail.value[ele] as Array<string>).join(';');
                        }
                    } else {
                        detailForm.value[index].text = deviceDetail.value[ele] as string;
                    }
                });
                projectType.value = deviceDetail.value.ProjectType;
            });
        };
        watch(
            community.ProjectType,
            () => {
                const projectData = getProjectData()[community.ProjectType.value];
                detailForm.value = projectData.detailForm;
                deviceType.value = projectData.deviceType;
                getPMDeviceInfo();
            },
            {
                immediate: true
            }
        );

        const isShowDeviceInfoSettingDialog = ref(false);
        const closeInfo = () => {
            isShowDeviceInfoSettingDialog.value = false;
            getPMDeviceInfo();
        };
        onBeforeUnmount(() => {
            clearTimer();
            if (setTimeoutTimer) {
                clearTimeout(setTimeoutTimer);
            }
            isLoading.value = false;
        });

        function closeLoadingDialog() {
            isShowLoadingDialog.value = false;
            clearTimer();
            clearTimeout(setTimeoutTimer);
            isLoading.value = false;
        }
        return {
            projectType,
            breadCrumbHeaders,
            detailForm,
            titleType,
            labelType,
            publicType,
            labelCustomize,
            IsShowBuildNames,
            deviceDetail,
            isShowDeviceInfoSettingDialog,
            closeInfo,
            remote,
            isShowJumpPage,
            jumpUrl,
            isShowLoadingDialog,
            closeLoadingDialog
        };
    }
});
